<div *ngIf="chartData.length > 0">
    <h4 class="text-center">{{ 'RESULTS.TIT_POSITION_CHART' | translate }}</h4>
    <canvas baseChart [height]="run.results.classification.length * 15"
    [datasets]="chartData"
    [labels]="chartLabels"
    [options]="chartOptions"
    [colors]="chartColors"
    [legend]="chartLegend"
    [chartType]="chartType">
    </canvas>
</div>