// tslint:disable: typedef-whitespace
import { RaceInfo } from './race.interface';
import { Racer } from './racer.interface';
import { UserInfo } from './userInfo.interface';

export class Championship {
    chId:     string;
    userInfo: UserInfo;
    data:     ChampionshipResults;

    constructor() {
        this.data = new ChampionshipResults();
        this.userInfo = new UserInfo();
    }
}

export class ChampionshipResults {
    id:             number;
    name:           string;
    totalRaces:     number;
    class:          string;
    racers:         Racer[];
    races:          RaceInfo[];
    classification: ChampionshipClassification[];

    constructor() {
        this.racers = [];
        this.races  = [];
        this.classification = [];
    }
}

export interface ChampionshipClassification {
    racerid: number;
    points:  Point[];
    total:   string;
    avg:     string;
    racerInfo?: Racer;
}

export interface Point {
    p:     number;
    s:     number;
    valid: number;
}

