<div style="display: block">
    <div style="display: flex; flex-wrap: wrap; align-items: stretch;">
        <app-club [club]="club"></app-club>
        <div class="zround-box ml-2" style="flex-grow: 3">
            <div class="row data">
                <div class="class ml-3">{{ championship.data.class }}</div>
                <div>
                    <h3>{{ championship.data.name }}</h3>
                </div>
                <div *ngIf="!selectedRace" style="margin-left:20px; font-style: italic; color: #34676e">
                    <h3>{{ 'RESULTS.CLASSIFICATION' | translate }}</h3>
                </div>
                <div *ngIf="selectedRace" style="font-style: italic; margin-left: 20px; color:green">
                    <h4>{{ selectedRace.name }}</h4>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <button [disabled]="!selectedRace" class="btn btn-info btn-championship mt-3 mr-2" (click)="selectedRace = null">
                        {{ 'RESULTS.CLASSIFICATION' | translate }}
                    </button>
                    <button *ngFor="let race of races" [disabled]="selectedRace === race.raceid || !race.raceid" class="btn btn-success btn-championship mr-2 mt-3" (click)="selectRace( race )">
                        {{ race.name }}
                    </button>
                </div>
            </div>
        </div>
    </div>    
    <div>
        <app-championship-points *ngIf="!selectedRace" [races]="races" [classification]="classification"></app-championship-points>
        <app-race *ngIf="selectedRace" [race]="selectedRace"></app-race>
    </div>
</div>