<div class="row">
    <div class="col">
        <app-run-classification 
            [classificationType]="classificationType"
            [run]="run">
        </app-run-classification>
    </div>
</div>

<div class="row">
    <div class="col">
        <app-lap-chart [run]="run"></app-lap-chart>
    </div>
</div>

<div class="row">
    <div class="col">
        <app-time-table [run]="run"></app-time-table>
    </div>
</div>

<div *ngIf="run && run.results" class="row" style="padding-bottom: 40px;">
    <div class="col">
        <div align="center">
            <h6 class="text-center">{{ 'RESULTS.TIMESTAMP_TITLE' | translate }}</h6>
            <table class="table table-sm timestampHeader">
                <tr>
                    <td class="timestampHeader">{{ 'RESULTS.TIMESTAMP_START' | translate }}</td>
                    <td>{{ run.results.start }}</td>
                </tr>
                <tr>
                    <td class="timestampHeader">{{ 'RESULTS.TIMESTAMP_FINISH' | translate }}</td>
                    <td>{{ run.results.finish }}</td>
                </tr>
            </table>
        </div>
    </div>
</div>