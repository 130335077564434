<table class="table table-sm table-striped resumen resumen-lt">
    <thead class="thead-dark">
        <tr>
            <th colspan="3">{{ title }}</th>
        </tr>
        <tr></tr>
    </thead>
    <tbody>
        <tr *ngFor="let racer of racers; index as i">
            <td>{{ i + 1 }}</td>
            <td style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">{{ racer.name }}
                <span class="racer_alias">{{ racer.alias }}</span>
            </td>
            <td class="d-none d-sm-table-cell text-left">{{ racer.club }}</td>
        </tr>
    </tbody>
</table>
