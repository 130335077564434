import { RaceInfo } from './race.interface';
import { UserInfo } from './userInfo.interface';
import { Racer } from 'src/app/interfaces/racer.interface';

// tslint:disable: typedef-whitespace
export class ZRoundEvent {
    evId:     string;
    userInfo: UserInfo;
    data:     ZRoundEventResults;
    racers?:  Racer[];

    constructor() {
        this.data = new ZRoundEventResults();
        this.userInfo = new UserInfo();
    }

    addRacers( racers: Racer[] ): void {
        if ( !this.racers ) {
            this.racers = [ ... racers ];
        } else {
            racers.forEach ( r => {
                const found = this.racers.find( eventRacer => eventRacer.id === r.id );
                if ( !found ) {
                    this.racers.push( r );
                }
            });
        }
    }

}

export class ZRoundEventResults {
    id:    string;
    name:  string;
    races: RaceInfo[];

    constructor() {
        this.races = [];
    }
}

