import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fecha'
})
export class FechaPipe implements PipeTransform {

  transform( fecha: string ): string {
    if ( !fecha ) {
      return '';
    } else if ( fecha === '0000-00-00 00:00:00' ) {
      return '';
    } else if ( fecha.substr( 10 ) === ' 00:00:00' ) {
      return fecha.substr( 0, 10 );
    } else {
      return fecha;
    }
  }

}
