<app-live-sessions [clubFilter]="false"></app-live-sessions>

<div class="row">
  <div class="col">
    <h5 class="section" [attr.id]="'championships'">{{ 'HOME.CHAMPIONSHIPS' | translate }}</h5>
  </div>	
</div>

<div class="row">
  <div class="col-lg-5">
    <app-publication-table id="chFav" [favorite]=true [object]="'championship'" [itemsPerPage]="itemsPerPage" (pageLoad)="chLoad( $event, true )"  [data]="chFavorites"></app-publication-table>
  </div>
  <div class="col-lg-5 offset-sm-1">
    <app-publication-table id="chAll" [favorite]=false [object]="'championship'" [itemsPerPage]="itemsPerPage" (pageLoad)="chLoad( $event, false )" [data]="chAll"></app-publication-table>
  </div>
</div>

<div class="row mt-5">
  <div class="col">
    <h5 class="section" [attr.id]="'events'">{{ 'HOME.EVENTS' | translate }}</h5>
  </div>	
</div>

<div class="row">
  <div class="col-lg-5">
    <app-publication-table id="evFav" [favorite]=true [object]="'event'" [itemsPerPage]="itemsPerPage" (pageLoad)="evLoad( $event, true )" [data]="evFavorites"></app-publication-table>
  </div>
  <div class="col-lg-5 offset-sm-1">
    <app-publication-table id="evAll" [favorite]=false [object]="'event'" [itemsPerPage]="itemsPerPage" (pageLoad)="evLoad( $event, false )" [data]="evAll"></app-publication-table>
  </div>
</div>

<div class="row mt-5">
  <div class="col">
    <h5 class="section" [attr.id]="'races'">{{ 'HOME.RACES' | translate }}</h5>
  </div>	
</div>

<div class="row pb-50">
  <div class="col-lg-5">
    <app-publication-table id="rcFav" [favorite]=true [object]="'race'" [itemsPerPage]="itemsPerPage" (pageLoad)="rcLoad( $event, true )" [data]="rcFavorites"></app-publication-table>
  </div>
  <div class="col-lg-5 offset-sm-1">
    <app-publication-table id="rcAll" [favorite]=false [object]="'race'" [itemsPerPage]="itemsPerPage" (pageLoad)="rcLoad( $event, false )" [data]="rcAll"></app-publication-table>
  </div>
</div>

