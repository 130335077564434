<div class="container-fluid p-0 pb-5">
  
  <app-navbar></app-navbar>

  <div style="height: 65px;"></div>

  <router-outlet></router-outlet>
  <app-footer></app-footer>

</div>
