import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

import { PublicationTable } from '../../interfaces/publication-table.interface';

@Component({
  selector: 'app-publication-table',
  templateUrl: './publication-table.component.html',
  styleUrls: [ './publication-table.component.css',
  ]
})
export class PublicationTableComponent  {

  private dataTable: PublicationTable;
  public page: number;
  public totalItems: number;
  public loading = true;

  @Input() favorite: boolean = false;
  @Input() itemsPerPage: number;
  @Input() object: string;
  @Input() id: string;
  @Input() showTitle: boolean = true;

  @Input() set data( info: PublicationTable) {
    this.dataTable = info;
    this.totalItems = info.paginationInfo.totalRecords;
    this.page = info.paginationInfo.page;
    this.loading = false;
  };

  get data(): PublicationTable {
    return this.dataTable;
  }

  @Output() pageLoad: EventEmitter<number> = new EventEmitter();

  private resultsUrl = environment.resultsUrl;

  userLogo( id: number ): string {
    return `${ this.resultsUrl }/api.php?rquest=userLogo&id=${ id }`;
  }

  constructor() {}

  pageChanged( page: number ): void {
    this.page = page;
    this.loading = true;
    this.pageLoad.emit( page );
  }

  getUrl( item: any ): string {

    switch( this.object ) {

      case 'event':
      case 'championship':

        return `/${ this.object  }/${ item.id }`;

      case 'race':

        if ( item.ch_id ) {
          return `/championship/${item.ch_id}/${ item.zid }`;
        } else {
          return `/event/${item.ev_id}/${ item.zid }`;
        }

      }

  }

}
