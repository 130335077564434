import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import Swal from 'sweetalert2';
import { UsuarioModel } from '../model/Usuario.model';

interface ConfigPaginacion {
  itemsPerPage: number;
  currentPage: number;
  totalItems: number;
}


@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: [
    './usuarios.component.css',
  ]
})
export class UsuariosComponent implements OnInit {

  public config: ConfigPaginacion;
  public cargando = true;
  public soloRegistrados = true;
  public filtro = '';
  public usuarios: UsuarioModel[] = [];

  // tslint:disable-next-line: variable-name
  constructor( public _auth: AuthService) {
    this.config = {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: 0,
    };
  }

  ngOnInit(): void {
    this.cargaUsuarios();
  }

  buscar( usuario: string ): void {
    this.filtro = usuario;
    this.cargaUsuarios();
  }

  filtrar( form: NgForm ): void {
    this.filtro = form.value.filtro;
    this.soloRegistrados = form.value.soloRegistrados;
    this.cargaUsuarios();
  }

  cargaUsuarios(): void {
    this.cargando = true;
    this._auth.getUsuarios( this.config.currentPage, this.config.itemsPerPage, this.soloRegistrados, this.filtro )
      .subscribe( usuarios => {
        this.config.totalItems = usuarios.totalRecords;
        this.usuarios = usuarios.users;
        this.cargando = false;
      }, ( error => {
        this.cargando = false;
        Swal.fire( 'Acceso denegado', 'Tu usuario no tiene suficientes permisos', 'error' );
        this._auth.logout();
      }));
  }

  cambiaPagina( pagina: any ): void {
    this.config.currentPage = pagina;
    this.cargaUsuarios();
  }

}
