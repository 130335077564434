<table class="table table-sm table-striped resumen puntosCampeonato mt-2">
    <thead class="thead-dark">
        <tr>
            <th width="25">{{ 'RESULTS.POS' | translate }}</th>
            <th>{{ 'RESULTS.NAME' | translate }}</th>
            <th class="d-none d-sm-table-cell">{{ 'RESULTS.CLUB' | translate }}</th>
            <th width="75" *ngFor="let race of races">{{ race.name }}</th>
            <th width="65">{{ 'RESULTS.TOTAL' | translate }}</th>
            <th width="60">{{ 'RESULTS.AVERAGE' | translate }}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let racer of classification; index as i">
            <td>{{ i + 1 }}</td>
            <td style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">{{ racer.racerInfo.name }}
                <span class="racer_alias">{{ racer.racerInfo.alias }}</span>
            </td>
            <td class="d-none d-sm-table-cell text-left">{{ racer.racerInfo.club }}</td>
            <td style="margin-right: 10px;" *ngFor="let points of racer.points">
                <div *ngIf="points.p > 0" class="d-flex">
                    <div class="posicion" [ngClass]="getPositionClass( points.p )">{{ points.p }}</div>
                    <div class="mx-auto" [ngStyle]="{ 'color': points.valid ? 'black' : 'red' }">{{ points.s }}</div>
                </div>
            </td>
            <td style="text-align: right; font-weight: bold; font-size: 15px;">{{ racer.total }}</td>
            <td style="text-align: right;">{{ racer.avg }}</td>
        </tr>
    </tbody>
</table>