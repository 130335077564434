import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Championship } from 'src/app/interfaces/championship.interface';
import { UserInfo } from 'src/app/interfaces/userInfo.interface';
import { ResultsService } from '../../services/results.service';
import { ChampionshipClassification } from '../../interfaces/championship.interface';
import { Racer } from 'src/app/interfaces/racer.interface';
import { RaceInfo, Race } from 'src/app/interfaces/race.interface';
import { Qualify } from '../../interfaces/race.interface';

@Component({
  selector: 'app-championship',
  templateUrl: './championship.component.html',
  styles: [
  ]
})
export class ChampionshipComponent {

  public championship: Championship = new Championship();
  // tslint:disable-next-line: variable-name
  private _selectedRace: Race = null;


  get selectedRace(): Race {
    return this._selectedRace;
  }

  set selectedRace( race: Race ) {
    this._selectedRace = race;

    let ruta: string;

    if ( race ) {
      const raceFound = this.championship.data.races.find( r => r.data  && ( r.data.raceid === race.raceid ));
      const zid = raceFound ? raceFound.raceid : 0;
      ruta = `/championship/${ this.championship.chId }/${ zid }`;
    } else {
      ruta = `/championship/${ this.championship.chId }`;
    }

    window.history.replaceState({}, '', ruta);
  }


  get club(): UserInfo {
    return this.championship.userInfo;
  }

  get races(): RaceInfo[] {
    return this.championship.data.races;
  }

  get classification(): ChampionshipClassification[] {
    return this.championship.data.classification;
  }

  get racers(): Racer[] {
    return this.championship.data.racers;
  }

  constructor( private route: ActivatedRoute,
               private results: ResultsService ) {
    route.params.subscribe( params => {
      this.getChampionship( params.id, params.raceid );
    });
  }

  getChampionship( id: number, zroundRaceId: string ): void {

    this.results.getChampionship( id )
      .subscribe( ( championship: Championship ) => {

        this.championship = championship;

        // Generate empty pending races
        for ( let i = this.championship.data.races.length + 1; i <= this.championship.data.totalRaces; i++ ) {
          const name = `--${ i }--`;
          const race = { name, raceid: null };
          this.championship.data.races.push( race );
        }

        // Set the racer detais for everyone
        this.classification.forEach( racerPoints => {
          const racer = this.getRacer( racerPoints.racerid );
          racerPoints.racerInfo = racer;
        });

        // If raceId parameter is informed go to the race details
        if ( zroundRaceId ) {
          this.loadRace( zroundRaceId );
        }

      });
  }

  getRacer( id: number ): Racer {
    return this.racers.find( racer => racer.id === id );
  }


  /*
   * Get Race data set to raceInfo.
   *  - selectRace force race selection when needed
   *  - qualifies racer info is completed and cached
   */
  getRaceData( race: RaceInfo, selectRace: boolean = true ): void {

    this.results.getRaceByZRoundId( race.raceid )
      .subscribe( ( raceData: Race ) => {
        // console.log('Race data loaded: ', raceData );
        race.dataAvailable = true;
        race.data = raceData;

        this.fillRacerInfo( raceData.prequalify, raceData.racers );
        this.fillRacerInfo( raceData.qualify, raceData.racers );
        this.fillRacerInfo( raceData.mains, raceData.racers );

        if ( selectRace ) {
          this.selectedRace = race.data;
        }
      });

  }

  /**
   * Set the racer detais for everyone (classification just include racerId)
   * racer details is completed to be used on reports
   *
   * ! Racer in qualify MUST exist in racers dictionary. Otherwise it could be an "unregistered racer" result.
   * ! It should be removed in ZRound before uploading results.
   *
   * @param qualify Qualift (prequalify, qualify, main) to fill in
   * @param racers  racers dictionarty to clone data from
   */
  fillRacerInfo( qualify: Qualify, racers: Racer[] ): void {
    qualify.classification.forEach( ( racerPoints, index ) => {
      const racer = racers.find( r => r.id === racerPoints.racerid );

      if ( !racer ) {
        qualify.classification.slice( index, 1 );
      } else {
        racerPoints.racerInfo = racer;
      }
    });
  }


  selectRace( race: RaceInfo ): void {

    if ( race.data && ( race.data === this.selectedRace )) {
      return;
    }

    if ( race.data ) {
      this.selectedRace = race.data;
      return;
    }

    // race found and it contains data (it's cached)
    // data does not exists. It must be loadad and cached.
    this.getRaceData( race );

  }

  loadRace( zroundRaceId: string ): void {

    const raceFound = this.championship.data.races.find( raceInfo => {
      return raceInfo.raceid === zroundRaceId;
    });

    if ( raceFound ) {
      this.selectRace( raceFound );
    }
  }

}
