import { Component, Input } from '@angular/core';
import { Lap, RunInfo } from '../../interfaces/run.interface';
import { Racer } from '../../interfaces/racer.interface';

@Component({
  selector: 'app-time-table',
  templateUrl: './time-table.component.html',
  styles: [
  ]
})
export class TimeTableComponent {

  // tslint:disable-next-line: variable-name
  private _run: RunInfo;

  // tslint:disable-next-line: variable-name
  private _means: number[];

  public timeTable: Lap[][] = [];

  @Input() set run ( r: RunInfo ) {
    this.setTimeTable( r );
    this._run = r;
  }

  get run(): RunInfo {
    return this._run;
  }

  constructor() { }

  getRacerName( racer: Racer ): string {
    return racer.alias ? racer.alias : racer.name;
  }

/*
 * Media Corregida es la media aritmética excluyendo los tiempos que son el doble de la mejor
 */
  getCorrectedMean( best: string, laps: Lap[] ): number {
    let mean = 0;
    let n = 0;
    let fBest = parseFloat(best);

    laps.forEach( item => {
      const laptime = parseFloat(item.v);
      if ( laptime < (fBest * 2)) {
        mean = mean += laptime;
        n++;
      }
    });

    if ( n > 0 ) {
      mean /= n;
    }
    return mean;
  }


  setTimeTable( r: RunInfo ): void {

    this._means = [];
    this.timeTable = [];

    if ( r && r.results ) {
      r.results.classification.forEach( ( item, index ) => {
        if ( item.racerid > 0 ) {
            this._means[ index ] = this.getCorrectedMean( item.best, item.laps );
        }
      });

      for (let nlap = 0; nlap < r.results.lapcount; nlap++ ) {
        const lap = [];
        r.results.classification.forEach( item => {
          if ( nlap < item.laps.length ) {
            lap.push( item.laps[ nlap ]);
          } else {
            lap.push( null );
          }

        });
        this.timeTable.push( lap );
      }
    }
  }

  getLapColor( racerIndex: number , lap: Lap ): string {

    if ( !lap ) {
      return 'black';
    }

    const cMean = this._means[ racerIndex ];
    const t = +lap.v.replace(',', '.');
    const m = +this.run.results.classification[ racerIndex ].mean.replace(',', '.');

    if ( this.bestLap( lap )) {
      return 'yellow';
    }

    if ( this.ownBest( racerIndex, lap )) {
      return '#00FF00';
    }

    if ( t > cMean * 2 ) {
      return 'red';
    }

    if ( t > cMean  * 1.5) {
      return 'orange';
    }

    if ( t > m * 1.05) {
      return 'yellow';
    }

    if ( t > m ) {
          return 'white';
    }

    if ( t > cMean * 0.95) {
      return '#01A733';
    }

    return 'lime';
  }

  ownBest( racerIndex: number, lap: Lap ): boolean {
    return lap && ( lap.v === this.run.results.classification[ racerIndex ].best );
  }

  bestLap( lap: Lap ): boolean {
    return lap && ( lap.v === this.run.results.bestlap );
  }


}
