import { Component, Input, OnInit } from '@angular/core';
import { RaceClassification } from 'src/app/interfaces/race.interface';
import { Racer } from 'src/app/interfaces/racer.interface';

@Component({
  selector: 'app-round-results',
  templateUrl: './round-results.component.html',
  styles: [
  ]
})
export class RoundResultsComponent implements OnInit {

  // tslint:disable-next-line: variable-name
  private _classification: RaceClassification;

  @Input() set classification( c: RaceClassification) {
    this._classification = c;
    // console.log( c );
  }
  get classification(): RaceClassification {
    return this._classification;
  }

  @Input() racers: Racer[];
  @Input() classificationType: string;

  // tslint:disable-next-line: variable-name
  private _racer: Racer;

  constructor() { }

  ngOnInit(): void {
  }

  get racer(): Racer {
    return this._racer;
  }

  getRacer( id: number ): Racer {
    this._racer = this.racers.find( r => r.id === id );
    return this._racer;
  }
}
