<div *ngIf="race">
    <div *ngIf="race.class" class="claseCarrera">{{ race.class }}</div>
    <div class="nombreCarrera">{{ race.name }}</div>
    <table class="table table-sm table-striped resumen animated fadeIn fast">
        <thead class="thead-dark">
            <tr>
                <th width="25">{{ 'RESULTS.POS' | translate }}</th>
                <th>{{ 'RESULTS.NAME' | translate }}</th>
                <th class="hidden-xs">{{ 'RESULTS.CLUB' | translate }}</th>
            </tr>
        </thead>
        <tbody *ngIf="race.mains">
            <tr *ngFor="let racer of race.mains.classification, index as i">
                <td>{{ i + 1 }}</td>
                <td>{{ getRacer(racer.racerid).name }}<span class="racer_alias">{{ getRacer(racer.racerid).alias }}</span></td>
                <td>{{ getRacer(racer.racerid).club }}</td>
            </tr>
        </tbody>
    </table>
</div>