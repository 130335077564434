import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LiveEvents, LiveEventItem } from '../interfaces/liveEvents.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UserInfo } from '../interfaces/userInfo.interface';


@Injectable({
  providedIn: 'root'
})
export class LiveService {

  private mockLiveData: LiveEvents = {
    paginationInfo: {
      totalRecords: 0,
      page: 0,
      pageNext: null,
    },
    events: [
      {
        logo: null,
        displayName: 'ZRound',
        sessionName: 'Practice',
        connectedUsers: 1,
        userId: 76,
        userName: 'jbroceno'
      },
    ],
  };

  get appHeaders(): any {

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Client-Id': environment.clientId,
      'Client-Secret': environment.clientSecret,
    });

    return headers;

  }

  constructor( public http: HttpClient ) { }

  /**
   * Convert item data array into  object
   * [null,"ZRound"," MODO ENTRENAMIENTO ",1,"76","jbroceno"]
   *  0. logo -> siempre null
   *  1. display-name
   *  2. session-name
   *  3. connectedClients
   *  4. userId
   *  5. userName
   *
   * @param e array of event data
   * @returns LiveEventItem
   */
  getEventItem( e: any[] ): LiveEventItem {
    return {
      logo: `${ environment.resultsUrl }/api.php?rquest=userLogo&id=${ e[4] }`,
      displayName: e[1],
      sessionName: e[2],
      connectedUsers: e[3],
      userId: e[4],
      userName: e[5],
    };
  }


  getLiveUser( userId: string ): Observable<LiveEvents> {
    const headers = new HttpHeaders(
      { version: '2' }
    );

    const url = `${ environment.liveApiUrl }/liveUser/${ userId }`;
    console.log( url, headers );
    return this.http.get<LiveEvents>( url, { headers })
    .pipe(
      map( liveEvents => {
        // console.log( liveEvents );
        liveEvents.eventList = [];
        liveEvents.events.forEach( e => {
          const event = this.getEventItem( e );
          liveEvents.eventList.push( event );
        });
        return liveEvents;
      })
    );

  }


  getLiveList( page: number, itemsPerPage: number ): Observable<LiveEvents> {

    const headers = new HttpHeaders(
      { version: '2' }
    );

    return this.http.get<LiveEvents>(`${ environment.liveApiUrl }/liveList`, { headers } )
      .pipe(
        map( liveEvents => {
          // console.log( liveEvents );
          liveEvents.eventList = [];
          liveEvents.events.forEach( e => {
            const event = this.getEventItem( e );
            liveEvents.eventList.push( event );
          });
          return liveEvents;
        })
      );
  }

}
