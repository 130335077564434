import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { RaceInfo, Race, Qualify } from 'src/app/interfaces/race.interface';
import { Racer } from 'src/app/interfaces/racer.interface';
import { UserInfo } from 'src/app/interfaces/userInfo.interface';
import { ZRoundEvent } from 'src/app/interfaces/zroundEvent.interface';
import { ResultsService } from 'src/app/services/results.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: [
    './event.component.css',
  ]
})
export class EventComponent {

  public event: ZRoundEvent = new ZRoundEvent();

  // tslint:disable-next-line: variable-name
   private _selectedRace: Race = null;

   get selectedRace(): Race {
    return this._selectedRace;
  }

  set selectedRace( race: Race ) {
    this._selectedRace = race;

    let ruta: string;

    if ( race ) {
      const raceFound = this.event.data.races.find( r => r.data  && ( r.data.raceid === race.raceid ));
      const zid = raceFound ? raceFound.raceid : 0;
      ruta = `/event/${ this.event.evId }/${ zid }`;
    } else {
      ruta = `/event/${ this.event.evId }`;
    }

    window.history.replaceState({}, '', ruta);
  }

  get club(): UserInfo {
    return this.event.userInfo;
  }

  get races(): RaceInfo[] {
    return this.event.data.races;
  }

  constructor(  private route: ActivatedRoute,
                private results: ResultsService ) {
    route.params.subscribe( params => {
      this.getEvent( params.id, params.raceid );
    });
  }

  /**
   * !  Events don't include racers dictionary. It is built based on existing racers declared into races.
   *
   * @param id event id
   * @param zroundRaceid Load event and navigate to specified race
   *
   */
  getEvent( id: number, zroundRaceid: string ): void {

    this.results.getEvent( id )
      .subscribe( ( event: ZRoundEvent ) => {
        // console.log( 'Event loaded', event );
        this.event.evId = event.evId;
        this.event.racers = null;
        this.event.data = event.data;
        this.event.userInfo = event.userInfo;

        // Load all races to show all the results
        this.races.forEach( r => {

          this.results.getRaceByZRoundId( r.raceid )
            .subscribe( ( race: Race ) => {
                this.event.addRacers( race.racers );
                r.data = race;
                r.dataAvailable = true;

                this.fillRacerInfo( r.data.prequalify, race.racers );
                this.fillRacerInfo( r.data.qualify, race.racers );
                this.fillRacerInfo( r.data.mains, race.racers );

                // Sometimes race name is empty, trying to recover from data
                if ( r.name.length === 0 ) {
                  r.name = race.name;
                }

                this.chkLoadCompleted( zroundRaceid );

              },
              ( error => {
                  console.warn( 'Race data not available ', error, r );
                  r.dataAvailable = true;
                  this.chkLoadCompleted( zroundRaceid );
              })
            );

        });
      });
  }

  chkLoadCompleted( zroundRaceid: string ): void {

    // if all races are loaded and the parameter raceid is on route then go to the race
    if ( this.loadCompleted() ) {
      if ( zroundRaceid ) {
        this.selectRace( this.races.find( raceLoaded => raceLoaded.raceid = zroundRaceid ) );
      }
    }
  }

  loadCompleted(): boolean {
    const pending = this.races.find( r => !r.dataAvailable );
    return !pending;
  }

  trackRace( item: number, race: RaceInfo ): number {
    return race.data ? race.data.raceid : 0;
  }

  selectRace( race: RaceInfo ): void {

    if ( race === null ) {
      this.selectedRace = null;
      return;
    }


    if ( race.data && ( race.data === this.selectedRace )) {
      return;
    }

    if ( race.data ) {
      this.selectedRace = race.data;
      return;
    }

    // race found and it contains data (it's cached)
    // data does not exists. It must be loadad and cached.
    this.getRaceData( race );

  }

  getRaceData( race: RaceInfo, selectRace: boolean = true ): void {

    if ( race.data ) {
      if ( selectRace ) {
        this.selectedRace = race.data;
      }
      return;
    }


    // race found and it contains data (it's cached)
    // data does not exists. It must be loadad and cached.
    this.results.getRaceByZRoundId( race.raceid )
      .subscribe( ( raceData: Race ) => {

        // console.log('Event Race data loaded: ', raceData );
        race.data = raceData;
        this.fillRacerInfo( raceData.prequalify, raceData.racers );
        this.fillRacerInfo( raceData.qualify, raceData.racers );
        this.fillRacerInfo( raceData.mains, raceData.racers );

        if ( selectRace ) {
          this.selectedRace = race.data;
        }

      },
      ( error => console.warn( 'Race data not available', error, race ))
    );

  }

  /**
   * Set the racer detais for everyone (classification just include racerId)
   * racer details is completed to be used on reports
   *
   * ! Racer in qualify MUST exist in racers dictionary. Otherwise it could be an "unregistered racer" result.
   * ! It should be removed in ZRound before uploading results.
   *
   * @param qualify Qualift (prequalify, qualify, main) to fill in
   * @param racers  racers dictionarty to clone data from
   */
   fillRacerInfo( qualify: Qualify, racers: Racer[] ): void {

    qualify.classification.forEach( ( racerPoints, index ) => {
      const racer = racers.find( r => r.id === racerPoints.racerid );
      if ( !racer ) {
        qualify.classification.slice( index, 1 );
      } else {
        racerPoints.racerInfo = racer;
      }

    });
  }


}
