import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { UsuarioModel } from '../model/Usuario.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: [
    './usuario.component.css',
  ]
})
export class UsuarioComponent implements OnInit {

  public form: FormGroup;
  public usuario: UsuarioModel;
  public roles: string[] = [];
  public imagenASubir: File;
  public imgTemp: any;

  constructor( private fb: FormBuilder,
               private activatedRouter: ActivatedRoute,
               private router: Router,
               private authService: AuthService ) {
  }

  ngOnInit(): void {
    this.crearFormulario();
    this.activatedRouter.params.subscribe( params => {
      this.cargarUsuario( params.id );
      this.cargaRoles();
    });
  }

  get rolNoValido(): boolean {
    return this.form.get('role').invalid && this.form.get('role').touched;
  }

  get loginNoValido(): boolean {
    return this.form.get('login').invalid && this.form.get('login').touched;
  }

  crearFormulario(): void {

    this.form = this.fb.group({
      id          : [''],
      login       : ['', Validators.required ],
      user_email  : [''],
      role        : ['', Validators.required ],
      display_name: [''],
      www         : [''],
      slogan      : [''],
      active      : [''],
      auth_key    : [''],
      liveEnabled: [''],
      live_start_date: [''],
      live_expiration: [''],
    });

  }

  enviar(): void {
    if ( this.form.invalid ) {
      console.log('Form inválido ');
      Swal.fire( 'Error de validación', 'El formulario tiene errores', 'error' );
      return;
    }

    this.usuario = { img: this.usuario.img, ...this.form.value };

    console.log( this.usuario );
    Swal.fire({
      title: 'Guardando cambios',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    this.authService.guardaUsuario( this.usuario, this.imagenASubir )
      .subscribe( ( resp: UsuarioModel ) => {

          if ( !this.usuario.id ) {

            this.usuario.id = resp.id;
            this.form.patchValue({ id: resp.id });
            Swal.fire( 'Usuario creado', `Se ha creado el usuario <${resp.id}>` );

          } else {

            Swal.fire( 'Usuario actualizado', `El usuario se ha actualizado correctamente` );

          }
        },
        ( error => {
            Swal.fire( 'Usuario NO actualizado', `Se ha producido un error. ${ error.error.msg }`, 'error' );
        })
      );
  }

  private getInputDateTime( fecha: string ): string {
    if ( fecha ) {
      return fecha.replace( ' ',  'T' );
    } else {
      return null;
    }
  }


  cargaForm(): void {
    const data: any = { ...this.usuario };
    data.active =  this.usuario.status && ( this.usuario.status !== '0' );
    data.liveEnabled = this.usuario.live_enabled && ( this.usuario.live_enabled !== '0' );
    if (!data.active) {
      data.active = '';
    }
    delete data.status;
    delete data.live_enabled;
    delete data.created_on;
    delete data.img;
    data.live_start_date = this.getInputDateTime( data.live_start_date );
    data.live_expiration = this.getInputDateTime( data.live_expiration );
    this.form.setValue( data );
  }

  cargaRoles(): void {
    this.authService.getRoles()
      .subscribe( roles => {
        this.roles = this.authService.roles;
      });
  }

  cargarUsuario( login: string ): void {

    // if ( login === '_nuevo') {
    //   this.usuario = {
    //     id: '',
    //     login: '',
    //     user_email: '',
    //     status: '1',
    //     role: 'USER',
    //     created_on: '',
    //     auth_key: '',
    //     live_enabled: '1',
    //     live_start_date: '',
    //     live_expiration: '',
    //     display_name: '',
    //     www: '',
    //     slogan: '',
    //     img: '',
    //   }
    //   this.cargaForm();
    //   return;
    // }

    // console.log( 'usuarios: ',this.authService.usuarios);
    if ( !this.authService.usuarios || ( this.authService.usuarios.length === 0 ) ) {
      // console.log( 'usuarios: ',this.authService.usuarios);
      this.router.navigate(['usuarios']);
    }

    const usr = this.authService.usuarios.find( u => u.login === login );
    if ( usr ) {
      this.usuario = usr;
      if ( !usr.id ) { // New user, auto generate activation key
        this.usuario.auth_key = this.authService.generateRandom();
        this.usuario.role = 'USER';
      }
      this.cargaForm();
    } else {
      // Esto no debería pasar, si ocurre es que han intentado entrar en la ruta forzando un id en lugar de navegando
      this.router.navigate(['usuarios']);
    }

  }

  regeneraAuth(): void {
    this.form.patchValue({ auth_key: this.authService.generateRandom()} );
  }

  cambiarImagen( fichero: File ) {
    this.imagenASubir = fichero;

    if ( !fichero ) { return this.imgTemp = null; }

    const reader = new FileReader();
    reader.readAsDataURL( fichero );

    reader.onload = () => {
      this.imgTemp = reader.result;
    };
  }


}
