import { Component } from '@angular/core';
import { VERSION } from 'src/environments/version';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: [ './footer.component.css',
  ]
})
export class FooterComponent {

  public CopyRight;
  public SOFT_VERSION = VERSION;

  constructor() { 
    const y = new Date().getFullYear();
    this.CopyRight = `© 2006-${ y } ZRound`;
  }

}
