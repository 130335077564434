/*
 * Classification Component
 * Display classification overview on Qualifying / Mains Phase
 *
 */

import { Component, Input, OnInit } from '@angular/core';
import { Qualify, Round, RaceClassification } from '../../interfaces/race.interface';

@Component({
  selector: 'app-classification',
  templateUrl: './classification.component.html',
  styles: [
  ]
})
export class ClassificationComponent implements OnInit {

  @Input() qualify: Qualify;
  @Input() phase: string;

  public racerResult: RaceClassification;

  get roundType(): string {
    if ( this.phase === 'RESULTS.CLASSIFICATION' ) {
      return 'Q';
    } else {
      return 'FINAL';
    }
  }

  positionClass( pos: number ): string {
    return `posicion-p${ pos }`;
  }

  get classificationType(): string {

    if ( this.qualify.points ) {
      return 'points';
    }

    if ( this.qualify.classificationType === 0 ) {
      return 'laps';
    }

    return 'best-lap';
  }

  getRacerResult( racerClassification: RaceClassification, round: Round ): RaceClassification {
    this.racerResult = round.classification.find( c => c.racerid === racerClassification.racerid );
    return this.racerResult;
  }


  getClassificationResult( classification: RaceClassification ): string {

    switch ( this.classificationType ) {

      case 'points':
        return classification.points.toString();

      case 'laps':
        return ` ${ classification.lapcount }L / ${ classification.time }`;

      case 'best-lap':
        return classification.best;

    }
  }


  constructor() { }

  ngOnInit(): void {
      // console.log( this.qualify );
  }
}
