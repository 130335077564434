import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { PublicationTable } from '../interfaces/publication-table.interface';
import { UserInfo } from '../interfaces/userInfo.interface';
import { Championship } from '../interfaces/championship.interface';
import { Race } from '../interfaces/race.interface';
import { ZRoundEvent } from '../interfaces/zroundEvent.interface';
import { RunInfo, RunResults } from '../interfaces/run.interface';


const resultsApiUrl = `${ environment.resultsUrl }/api.php`;

@Injectable({
  providedIn: 'root'
})
export class ResultsService {

  public userId: number;
  public loadedUsers: number = 0;

  private mockTableData: PublicationTable = {
    items: [
        {
            date: new Date('2016-05-08'),
            name: 'Social 2016 - Fase 2',
            logo: null,
            userDisplayName: 'RC MadriZ',
            class: 'MiniZ Racer',
            id: '1',
            userId: '1'
        },
        {
            date: new Date('2016-09-06'),
            name: 'Invitacional CCA 1-8',
            logo: null,
            userDisplayName: 'Mi Hobby RC',
            class: '1-8 GT',
            id: '21',
            userId: '6'
        },
        {
            date: new Date('2016-09-11'),
            name: 'TEST LCH',
            logo: null,
            userDisplayName: 'Rccallosa',
            class: '1-10 electrico',
            id: '57',
            userId: '19'
        },
        {
            date: new Date('2016-05-02'),
            name: 'Final a b c nacional',
            logo: null,
            userDisplayName: 'Mi Hobby RC',
            class: '1-18',
            id: '22',
            userId: '6'
        },
        {
            date: new Date('2016-04-30'),
            name: 'Interclubs-2015',
            logo: null,
            userDisplayName: 'InterClubs MiniZ Series',
            class: 'MiniZ Racer',
            id: '6',
            userId: '3'
        },
        {
            date: new Date('2016-10-02'),
            name: 'Interclubs 2016',
            logo: null,
            userDisplayName: 'InterClubs MiniZ Series',
            class: 'MiniZ Racer',
            id: '7',
            userId: '3'
        },
        {
            date: new Date('2016-05-15'),
            name: 'ACAM Regional 1-10',
            logo: null,
            userDisplayName: 'Mi Hobby RC',
            class: '1-10 Electricos',
            id: '20',
            userId: '6'
        },
        {
            date: new Date('2016-04-30'),
            name: 'Stock 2016',
            logo: null,
            userDisplayName: 'RC MadriZ',
            class: 'MiniZ Racer',
            id: '18',
            userId: '1'
        },
        {
            date: new Date('2016-04-30'),
            name: 'Social 2016 - Fase 1',
            logo: null,
            userDisplayName: 'RC MadriZ',
            class: 'MiniZ Racer',
            id: '19',
            userId: '1'
        },
        {
            date: new Date('2016-11-21'),
            name: 'Rc River Cup 2016',
            logo: null,
            userDisplayName: 'Rc River Track',
            class: '1-8 Buggy',
            id: '23',
            userId: '7'
        }
    ],
    paginationInfo: {
        totalRecords: 475,
        pageNext: null,
        page: 1
    }
  };

  constructor( public http: HttpClient ) { }

  get appHeaders(): any {

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Client-Id': environment.clientId,
      'Client-Secret': environment.clientSecret,
    });

    return headers;

  }

  searchForUsers( termino: string, page: number ): Observable<any> {

    let params = new HttpParams();
    params = params.append( 'rquest', 'user/search' );
    params = params.append( 'name', termino );
    params = params.append( 'page', page.toString() );

    let headers = this.appHeaders;
    headers = headers.append( 'Version', '2' );

    return this.http.get(`${ resultsApiUrl }`, { params, headers })
                .pipe(
                  map( ( resp: any ) => {
                    return resp;
                  })
                );
  }


  getChampionships( page: number, itemsPerPage: number, favorites: boolean, userId?: string ): Observable<PublicationTable> {

    // return of( this.mockTableData );

    let params = new HttpParams();
    params = params.append( 'rquest', 'championship/list' );
    params = params.append( 'pageSize', itemsPerPage.toString() );
    params = params.append( 'page', page.toString() );
    params = params.append( 'type', favorites ? '1' : '0' );
    if ( userId ) {
      params = params.append( 'userInfo', userId );
    }

    let headers = this.appHeaders;
    headers = headers.append( 'Version', '2' );

    return this.http.get<PublicationTable>(`${ resultsApiUrl }`, { params, headers });

  }

  getEvents( page: number, itemsPerPage: number, favorites: boolean, userId?: string ): Observable<PublicationTable> {

    let params = new HttpParams();
    params = params.append( 'rquest', 'event/list' );
    params = params.append( 'pageSize', itemsPerPage.toString() );
    params = params.append( 'page', page.toString() );
    params = params.append( 'type', favorites ? '1' : '0' );
    if ( userId ) {
      params = params.append( 'userInfo', userId );
    }

    let headers = this.appHeaders;
    headers = headers.append( 'Version', '2' );

    return this.http.get<PublicationTable>(`${ resultsApiUrl }`, { params, headers });

  }

  getRaces( page: number, itemsPerPage: number, favorites: boolean, userId?: string ): Observable<PublicationTable> {

    let params = new HttpParams();
    params = params.append( 'rquest', 'race/list' );
    params = params.append( 'pageSize', itemsPerPage.toString() );
    params = params.append( 'page', page.toString() );
    params = params.append( 'type', favorites ? '1' : '0' );
    if ( userId ) {
      params = params.append( 'userInfo', userId );
    }

    let headers = this.appHeaders;
    headers = headers.append( 'Version', '2' );

    return this.http.get<PublicationTable>(`${ resultsApiUrl }`, { params, headers });

  }


  getUserInfo( id: number ): Observable<UserInfo> {

    let params = new HttpParams();
    params = params.append( 'rquest', 'userInfo' );
    params = params.append( 'user', id.toString() );

    return this.http.get<UserInfo>(`${ resultsApiUrl }`, { params });
  }


  getChampionship( id: number ): Observable<Championship> {

    let params = new HttpParams();
    params = params.append( 'rquest', 'championship' );
    params = params.append( 'id', id.toString() );

    let headers = this.appHeaders;
    headers = headers.append( 'Version', '2' );

    return this.http.get<Championship>(`${ resultsApiUrl }`, { params, headers });

  }


  getRaceByZRoundId( id: string ): Observable<Race> {

    let params = new HttpParams();
    params = params.append( 'rquest', 'race' );
    params = params.append( 'zid', id );

    let headers = this.appHeaders;
    headers = headers.append( 'Version', '2' );

    return this.http.get<Race>(`${ resultsApiUrl }`, { params, headers });
  }



  getRunDictionary( raceId: number ): Observable<RunInfo[]> {

    let params = new HttpParams();
    params = params.append( 'rquest', 'run/dictionary' );
    params = params.append( 'raceId', raceId.toString() );

    return this.http.get<RunInfo[]>(`${ resultsApiUrl }`, { params });
  }


  getRun( runId: number ): Observable<RunResults> {

    let params = new HttpParams();
    params = params.append( 'rquest', 'run' );
    params = params.append( 'id', runId.toString() );

    let headers = this.appHeaders;
    headers = headers.append( 'Version', '2' );

    return this.http.get<RunResults>(`${ resultsApiUrl }`, { params, headers });
  }


  getEvent( id: number ): Observable<ZRoundEvent> {

    let params = new HttpParams();
    params = params.append( 'rquest', 'event' );
    params = params.append( 'id', id.toString() );

    let headers = this.appHeaders;
    headers = headers.append( 'Version', '2' );

    return this.http.get<ZRoundEvent>(`${ resultsApiUrl }`, { params, headers });

  }


}
