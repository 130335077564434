import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import { RunInfo } from '../../interfaces/run.interface';
import { Racer } from '../../interfaces/racer.interface';

@Component({
  selector: 'app-lap-chart',
  templateUrl: './lap-chart.component.html',
  styleUrls: [ ]
})
export class LapChartComponent {

  // tslint:disable-next-line: variable-name
  private _colorLines = ['#EE3E34', '#1D4F9C', '#F09243', '#54B948', '#BDA0CC', '#EEC3D0', '#772120', '#221F1F', '#FDE92B', '#FFE1BA' ];

  // tslint:disable-next-line: variable-name
  private _run: RunInfo;

  @Input() set run( r: RunInfo ) {

    this._run = null;
    if ( r && r.results ) {
      this._run = r;
      this.setChartInfo( r );
    }

  }

  get run(): RunInfo {
    return this._run;
  }

  public chartData: ChartDataSets[] = [];

  public chartLabels: Label[] = [];

  public chartOptions: (ChartOptions) = {
    elements: {
      line: {
        tension: 0.1,
      }
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          id: 'A',
          position: 'left',
          ticks: {
            fontColor: 'gray',
            fontSize: 14,
            min: 0,
            stepSize: 1,
            autoSkip: false,
            reverse: true,
            callback: ( value, index, values ) => {
              if ( ( index === 0 ) || ( index === (values.length - 1) )) {
                return '';
              }
              if ( this.run ) {
                return `${ this.getRacerAlias( this.getRacerGrid( index - 1 ) ) } - ${ index }`;
              } else {
                return '';
              }
            }
          },
        },
        {
          id: 'B',
          position: 'right',
          gridLines: {
            color: 'rgba(0,0,255,0.3)',
          },
          ticks: {
            fontColor: 'gray',
            fontSize: 14,
            min: 0,
            stepSize: 1,
            autoSkip: false,
            reverse: true,
            callback: ( value, index, values ) => {
              if ( ( index === 0 ) || ( index === (values.length - 1) )) {
                return '';
              }
              if ( this.run ) {
                return `${ index } - ${ this.getRacerAlias( ( index <= this.run.results.classification.length ) ? this.run.results.classification[ index - 1 ].racerInfo : null ) }`;
              } else {
                return '';
              }
            }
          }
        }
      ]
    },
  };

  public chartColors: Color[] = [];
  public chartLegend = false;
  public chartType: ChartType = 'line';

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  constructor() { }

  getRacerAlias( r: Racer ): string {

    if ( r ) {
      return r.alias ? r.alias : r.name;
    }
    return '';

  }

  getRacerGrid( index: number ): Racer {
    if ( index >= this.run.results.grid.length ) {
      return null;
    }

    const racerId = this.run.results.grid[ index ];
    const racer = this.run.results.classification.find( r => racerId === r.racerid );
    if ( racer ) {
      return racer.racerInfo;
    }

    return null;
  }

  getRacerStartPosition( racerId: number ): number {
    return this.run.results.grid.indexOf( racerId );
  }

  setChartLabels( r: RunInfo ): void {
    this.chartLabels = [ 'Grid' ];
    for ( let i = 1; i <= r.results.lapcount; i++ ) {
      this.chartLabels.push( `${ i.toString() }L`);
    }
  }

  setChartInfo( r: RunInfo ): void {

    this.chartColors = [];
    this.chartData = [];

    this.setChartLabels( r );

    this.chartOptions.scales.yAxes[0].ticks.max = r.results.classification.length + 1;
    this.chartOptions.scales.yAxes[1].ticks.max = r.results.classification.length + 1;

    r.results.classification.forEach( ( racer, index ) => {

      const racerColor = this._colorLines[ index % this._colorLines.length ];
      const color = {
        color: racerColor,
        borderColor: racerColor,
        pointBackgroundColor: racerColor,
        pointColor: racerColor,
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: false,
      };
      this.chartColors.push( color );

      const label = this.getRacerAlias( racer.racerInfo );
      const data = racer.laps.map( l => l.p );
      data.unshift( this.getRacerStartPosition( racer.racerid) + 1 );
      const serie = {
        label,
        data,
        yAxisID: 'B'
      };
      this.chartData.push( serie );
    });

    this.chartOptions = { ... this.chartOptions };
  }


}
