/*
 * run-results component
 *
 * Display run detailed view (results, lap-chart, lap-times report)
 */
import { Component, Input, OnInit } from '@angular/core';
import { Racer } from 'src/app/interfaces/racer.interface';
import { RunInfo } from 'src/app/interfaces/run.interface';

@Component({
  selector: 'app-run-results',
  templateUrl: './run-results.component.html',
  styles: [
  ]
})
export class RunResultsComponent implements OnInit {

  @Input() run: RunInfo;
  @Input() classificationType: string;

  constructor() { }

  ngOnInit(): void {
  }

}
