<div class="container-fluid">

    <div class="row mt-3">

        <div class="col-sm-4">
            <div class="form-group">
                <select class="form-control selector" [(ngModel)]="viewSelected" (change)="viewOptionChanged()">
                    <option [ngValue]="option" *ngFor="let option of viewOptions">{{ option.option | translate}}
                    </option>
                </select>
            </div>
        </div>

        <div class="col-sm-4">
            <div *ngIf="viewSelected.value !== '0'" class="form-group">
                <select class="form-control selector" [(ngModel)]="qualifySelected" (change)="qualifyOptionChanged()">
                    <option [ngValue]="option" *ngFor="let option of qualifyOptions">{{ option.option | translate}}
                    </option>
                </select>
            </div>
        </div>

        <div class="col-sm-4">
            <div *ngIf="qualifySelected && qualifySelected.value !== '0'" class="form-group">
                <select class="form-control selector" [(ngModel)]="roundSelected" (change)="roundOptionChanged()">
                    <option [ngValue]="option" *ngFor="let option of roundOptions">{{ option.option | translate}}
                    </option>
                </select>
            </div>
        </div>

    </div>

    <div *ngIf="viewSelected.value === '0'" class="row">

        <div class="col-sm-4">
            <app-ranking-q *ngIf="prequalifyAvailable" [title]="'RESULTS.RANKING_START' | translate" [qualify]="race.prequalify"></app-ranking-q>
            <app-ranking-start *ngIf="!prequalifyAvailable" [racers]="racers" [title]="'RESULTS.RANKING_START' | translate"></app-ranking-start>
        </div>

        <div class="col-sm-4">
            <app-ranking-q [title]="'RESULTS.QUALIFY' | translate" [qualify]="race.qualify"></app-ranking-q>
        </div>

        <div class="col-sm-4">
            <app-ranking-q [title]="'RESULTS.MAINS' | translate" [qualify]="race.mains"></app-ranking-q>
        </div>

    </div>

    <div *ngIf="qualifySelected && qualifySelected.value === '0' && classificationQualify" class="row">
        <div class="col">
            <app-classification [phase]="classificationPhase" [qualify]="classificationQualify"></app-classification>
        </div>
    </div>

    <div *ngIf="roundSelected && roundSelected.value ==='0'" class="row">
        <div class="col">
            <app-round-results [racers]="racers" [classificationType]="classificationType" [classification]="round.classification"></app-round-results>
        </div>
    </div>

    <div *ngIf="roundSelected && roundSelected.value !=='0'">
        <app-run-results [classificationType]="classificationType" [run]="run"></app-run-results>
    </div>
</div>