import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { UsuariosComponent } from './usuarios/usuarios.component';
import { LoginComponent } from './login/login.component';
import { UsuarioComponent } from './usuario/usuario.component';
import { AuthGuard } from '../guards/auth.guard';

const routes: Routes = [
  { path: 'usuarios',      component: UsuariosComponent,
      canActivate: [
        AuthGuard
      ]},
  { path: 'usuario/:id',   component: UsuarioComponent,
      canActivate: [
        AuthGuard
      ]},
  { path: 'login',         component: LoginComponent },
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdmRoutingModule {}