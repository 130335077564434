/*
 *  Race-results component
 *
 *  Display Race Class - Race Name
 *  Race results after mains
 *
 *  Used to show event overview with all race results on the same view.
 */
import { Component, Input, } from '@angular/core';
import { Race } from 'src/app/interfaces/race.interface';
import { Racer } from 'src/app/interfaces/racer.interface';

@Component({
  selector: 'app-race-results',
  templateUrl: './race-results.component.html',
  styleUrls: ['./race-results.component.css']
})
export class RaceResultsComponent {

  @Input() race: Race;

  constructor() { }


  getRacer( id: number ): Racer {
    return this.race.racers.find( r => r.id === id );
  }
}
