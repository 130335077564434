// tslint:disable: typedef-whitespace
// tslint:disable: variable-name
export class UserInfo {
    id:           string;
    display_name: string;
    www:          string;
    slogan:       string;
    logo_size:    string;
    img:          string;
}
