import { Component, Input, OnInit } from '@angular/core';
import { RunInfo } from '../../interfaces/run.interface';
import { Racer } from '../../interfaces/racer.interface';

@Component({
  selector: 'app-run-classification',
  templateUrl: './run-classification.component.html',
  styles: [
  ]
})
export class RunClassificationComponent implements OnInit {

  @Input() run: RunInfo;
  @Input() classificationType: string;

  constructor() { }

  ngOnInit(): void {
  }

}
