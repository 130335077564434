import { Component } from '@angular/core';
import { ResultsService } from '../../services/results.service';
import { PublicationTable, PublicationTableItem } from '../../interfaces/publication-table.interface';
import { environment } from 'src/environments/environment';

const itemsPerPage = environment.itemsPerPage;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles: [
  ]
})
export class HomeComponent  {

  public chFavorites: PublicationTable = new PublicationTable();
  public chAll: PublicationTable = new PublicationTable();
  public evFavorites: PublicationTable = new PublicationTable();
  public evAll: PublicationTable = new PublicationTable();
  public rcFavorites: PublicationTable = new PublicationTable();
  public rcAll: PublicationTable = new PublicationTable();

  public itemsPerPage = environment.itemsPerPage;

  // tslint:disable-next-line: variable-name
  constructor( private _results: ResultsService ) {

    this.chLoad( 1, true );
    this.chLoad( 1 );

    this.evLoad( 1, true );
    this.evLoad( 1 );

    this.rcLoad( 1, true );
    this.rcLoad( 1 );

  }

  chLoad( page: number, favorite: boolean = false ): void {

    this._results.getChampionships( page, itemsPerPage, favorite )
    .subscribe( ( championships ) => {
      // console.log( 'Championships:', championships );
      if ( favorite ) {
        this.chFavorites = championships;
      } else {
        this.chAll = championships;
      }
    });
  }

  evLoad( page: number, favorite: boolean = false ): void {

    this._results.getEvents( page, itemsPerPage, favorite )
    .subscribe( ( events ) => {
      // console.log( 'Events:', events );
      if ( favorite ) {
        this.evFavorites = events;
      } else {
        this.evAll = events;
      }
    });
  }

  rcLoad( page: number, favorite: boolean = false ): void {
    this._results.getRaces( page, itemsPerPage, favorite )
    .subscribe( ( races ) => {
      // console.log( 'Races:', races );
      if ( favorite ) {
        this.rcFavorites = races;
      } else {
        this.rcAll = races;
      }
    });
  }

}
