<div class="container">
  <app-club *ngIf="club" [club]="club"></app-club>

  <div class="row mt-2">
    <div class="col">
      <app-live-sessions [club]="club" [clubFilter]="true"></app-live-sessions>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col">
      <h5 class="section" [attr.id]="'championships'">{{ 'HOME.CHAMPIONSHIPS' | translate }}</h5>
    </div>	
  </div>
  
  <div class="row justify-content-center">
    <div class="col-sm-5">
      <app-publication-table id="chAll" [showTitle]="false" [favorite]=false [object]="'championship'" [itemsPerPage]="itemsPerPage" (pageLoad)="chLoad( $event )" [data]="chAll"></app-publication-table>
    </div>
  </div>
  
  <div class="row mt-5">
    <div class="col">
      <h5 class="section" [attr.id]="'events'">{{ 'HOME.EVENTS' | translate }}</h5>
    </div>	
  </div>
  
  <div class="row justify-content-center">
    <div class="col-sm-5">
      <app-publication-table id="evAll" [showTitle]="false" [favorite]=false [object]="'event'" [itemsPerPage]="itemsPerPage" (pageLoad)="evLoad( $event )" [data]="evAll"></app-publication-table>
    </div>
  </div>
  
  <div class="row mt-5">
    <div class="col">
      <h5 class="section" [attr.id]="'races'">{{ 'HOME.RACES' | translate }}</h5>
    </div>	
  </div>
  
  <div class="row justify-content-center pb-50">
    <div class="col-sm-5">
      <app-publication-table id="rcAll" [showTitle]="false" [favorite]=false [object]="'race'" [itemsPerPage]="itemsPerPage" (pageLoad)="rcLoad( $event )" [data]="rcAll"></app-publication-table>
    </div>
  </div>
</div>
  
