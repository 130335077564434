import { Component, Input } from '@angular/core';
import { RaceInfo } from 'src/app/interfaces/race.interface';
import { ChampionshipClassification } from '../../interfaces/championship.interface';

@Component({
  selector: 'app-championship-points',
  templateUrl: './championshipPoints.component.html',
  styles: [
  ]
})
export class ChampionshipPointsComponent {

  @Input() races: RaceInfo[];
  @Input() classification: ChampionshipClassification;

  getPositionClass( pos: number ): string {
    return `posicion-p${ pos }`;
  }

  constructor() { }

}
