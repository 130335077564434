<table *ngIf="run && run.results" class="table table-sm table-striped resumen">
    <thead class="thead-dark">
        <tr>
            <th>{{ 'RESULTS.POS' | translate }}</th>
            <th>{{ 'RESULTS.NAME' | translate }}</th>
            <th class='d-none d-sm-table-cell'>{{ 'RESULTS.CLUB' | translate }}</th>
            <th *ngIf="classificationType === 'laps'">{{ 'RESULTS.LAPS_TIME' | translate }}</th>
            <th *ngIf="classificationType !== 'laps'">{{ 'RESULTS.BEST' | translate }}</th>
            <th>{{ 'RESULTS.AVERAGE' | translate }}/{{ 'RESULTS.CONSISTENCY' | translate }}</th>
            <th>{{ 'RESULTS.INTERVAL' | translate }}</th>
            <th>{{ 'RESULTS.GAP' | translate }}</th>
            <th *ngIf="classificationType === 'laps'">{{ 'RESULTS.BEST' | translate }}</th>
            <th *ngIf="classificationType !== 'laps'">{{ 'RESULTS.LAPS_TIME' | translate }}</th>
        </tr>
    </thead>
    <tbody>
        <tr style="text-align:center" *ngFor="let item of run.results.classification; let i=index">
            <td><div class="posicion circulo-resaltado">{{ i + 1 }}</div></td>
            <td>{{ item.racerInfo.name }}<span class="racer_alias">{{ item.racerInfo.alias }}</span></td>
            <td class='d-none d-sm-table-cell'>{{ item.racerInfo.club }}</td>
            <td class="qualyResult" style="text-align: center; font-weight: bold;" *ngIf="classificationType === 'laps'">{{ item.lapcount }}L &nbsp;{{ item.time }}</td>
            <td class="qualyResult" style="text-align: center; font-weight: bold;" *ngIf="classificationType !== 'laps'">{{ item.best }}</td>
            <td>{{ item.mean }}<span class="consistencia">{{ item.consistency ? item.consistency : '' }}</span></td>
            <td>{{ item.diff.replace( 'Vueltas', 'L' ) }}</td>
            <td>{{ item.gap.replace( 'Vueltas', 'L' ) }}</td>
            <td *ngIf="classificationType === 'laps'" [ngClass]="{'qualifybestlap': item.best === run.results.bestlap}">{{ item.best }}</td>
            <td *ngIf="classificationType !== 'laps'">{{ item.lapcount }}L &nbsp;{{ item.time }}</td>
        </tr>
    </tbody>
</table>