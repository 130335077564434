import { Component, Input } from '@angular/core';
import { UserInfo } from 'src/app/interfaces/userInfo.interface';

@Component({
  selector: 'app-club',
  templateUrl: './club.component.html',
  styleUrls: [ './club.component.css',
  ]
})
export class ClubComponent {

@Input() club: UserInfo;

  constructor() { }


}
