<nav class="fixed-nav-bar navbar navbar-expand-lg navbar-dark bg-dark container-fluid animated fadeIn fast">
  <a class="navbar-brand" target="_blank" href="https://www.zround.com"></a>
  <img style="margin-top: -2px; background-color: #d0d0d0; border-radius: 10px;" [src]="logoUrl" height="50px">

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto ml-2">
      
      <li class="nav-item active">
        <a class="nav-link" href="#portal">{{ 'HOME.HOME' | translate }}</a>
      </li>
      
      <li class="nav-item" *ngIf=" _results.userId > 0 ">
        <a class="nav-link" routerLink="user.php">{{ 'HOME.MY_RACES' | translate }}</a>
      </li>
      
      <li class="nav-item">
        <a class="nav-link" [routerLink]="'.'" [fragment]="'events'">{{ 'HOME.EVENTS' | translate }}</a>
      </li>
      
      <li class="nav-item">
        <a class="nav-link" [routerLink]="'.'" [fragment]="'championships'">{{ 'HOME.CHAMPIONSHIPS' | translate }}</a>
      </li>
      
      <li class="nav-item">
        <a class="nav-link" [routerLink]="'.'" [fragment]="'races'">{{ 'HOME.RACES' | translate }}</a>
      </li>

      <li><button class="btn btn-outline" (click)="switchLang( 'es' )"><img src="./assets/img/es.bmp" /></button></li>
      <li><button class="btn btn-outline" (click)="switchLang( 'en' )"><img src="./assets/img/gb.bmp" /></button></li>

    </ul>
    <div class="search-box">
      <form class="form-inline my-2 my-lg-0" #f="ngForm" (ngSubmit)="buscar( f )" novalidate>
        <input class="form-control" type="search"  (ngModelChange)='searchChange($event)' name="termino" [ngModel]="terminoBusqueda" #termino="ngModel" [placeholder]="'HOME.SEARCH_PLACEHOLDER' | translate" aria-label="Search">
        <button class="btn btn-outline-success my-2 ml-2 mr-10" type="submit">{{ 'HOME.SEARCH_TRACKS' | translate}}</button>
      </form>
      <div class="result-box" *ngIf="searchResult.length > 0">
        <a *ngFor="let item of searchResult" [href]="'/track/' + item.id "><img class="avatar" [src]=" resultsUrl + '/api.php?rquest=userLogo&id=' + item.id "><span>{{ item.display_name }}</span></a>
        <button class="btn btn-outline-secondary btn-result-box-prev" *ngIf="pagination.page > 1" (click)="searchUser( pagination.page - 1)">{{ 'HOME.PAGINATION.paginate.previous' | translate }}</button>
        <button class="btn btn-outline-secondary btn-result-box-next" *ngIf="pagination.pageNext !== null" (click)="searchUser( pagination.pageNext )">{{ 'HOME.PAGINATION.paginate.next' | translate }}</button>
      </div>    
    </div>
    <div class="text-right" *ngIf="_auth.estaAutenticado">
      <button class="btn btn-danger mr-auto" (click)="logout()">Salir</button>
    </div>
  </div>
</nav>