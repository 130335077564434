import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'img'
})
export class ImgPipe implements PipeTransform {


  transform( imagen: string ): string {
    if ( !imagen ) {
      return './assets/img/no-img.jpg';
    } else {
      return imagen;
    }
  }

}
