<table class="table table-sm table-striped resumen resumen-lt">
    <thead class="thead-dark">
        <tr>
            <th colspan="3">{{ title }}</th>
        </tr>
        <tr></tr>
    </thead>
    <tbody>
        <tr *ngFor="let racer of qualify.classification; let i=index">
            <td>{{ i + 1 }}</td>
            <td>{{ racer.racerInfo.name }}<span class="racer_alias">{{ racer.racerInfo.alias }}</span>
            </td>
            <td>{{ racer.racerInfo.club }}</td>
        </tr>
    </tbody>
</table>

