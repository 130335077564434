import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupName'
})
export class GroupNamePipe implements PipeTransform {

  transform(value: number): string {
    // 1 -> A, 2-> B, etc.
    return String.fromCharCode( 64 + value );
  }

}
