<div class="mx-auto mt-3" *ngIf="run && run.results">
    <h4 class="text-center">{{ 'RESULTS.TIT_TIMETABLE' | translate }}</h4>
    <div align="center">
        <table class="table table-sm table-striped laps"
            style="width:0%; border-color: navy; border-style: solid; border-width: 1px;">
            <thead>
                <tr>
                    <th>#</th>
                    <th *ngFor="let racer of run.results.classification">{{ getRacerName(racer.racerInfo ) }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let laps of timeTable; let i=index">
                    <td>
                        {{ i + 1 }}
                    </td>
                    <td *ngFor="let lap of laps; let j=index"
                        width=120 
                        [ngClass]="{'ownbest': ownBest( j, lap ) && !bestLap( lap ), 'bestlap': bestLap( lap )}"
                        [ngStyle]="{'color': getLapColor( j, lap ) }">
                            <div *ngIf="lap" class="positionlaps">{{ lap.p }}</div>
                            <div *ngIf="lap" style="padding-left: 5px; padding-right: 5px">{{ lap.v.replace(',', '.') }}</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
