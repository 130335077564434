import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ResultsService } from '../../services/results.service';

import { Qualify, Race } from 'src/app/interfaces/race.interface';
import { RunInfo } from 'src/app/interfaces/run.interface';
import { Racer } from 'src/app/interfaces/racer.interface';
import { Round } from '../../interfaces/race.interface';

interface Option {
  option: string;
  value: string;
}

@Component({
  selector: 'app-race',
  templateUrl: './race.component.html',
  styleUrls: ['./race.component.css']
})
export class RaceComponent {

  // tslint:disable-next-line: variable-name
  private _runInfo: RunInfo[] = [];
  // tslint:disable-next-line: variable-name
  private _race: Race;

  private defaultOption: Option = { option: 'RESULTS.OVERVIEW', value: '0' };

  // Option for content selection
  public viewOptions: Option[] = [];
  public qualifyOptions: Option[] = [];
  public roundOptions: Option[] = [];

  public viewSelected: Option;
  public qualifySelected: Option;
  public roundSelected: Option;

  public round: Round;
  public run: RunInfo;

  @Input() set race( r: Race ) {
    this._race = r;
    this.setupRace( r );
  }

  get race(): Race {
    return this._race;
  }

  get racers(): Racer[] {
    return this._race.racers;
  }

  get prequalifyAvailable(): boolean {
    return this.race.prequalify && ( this.race.prequalify.classification.length > 0 );
  }

  get classificationQualify(): Qualify {

    let q: Qualify = null;

    switch ( this.viewSelected.value ) {

      case 'prequalify':
          q = this.race.prequalify;
          break;

      case 'qualify':
          q = this.race.qualify;
          break;

      case 'mains':
          q = this.race.mains;
          break;

    }

    return q;

  }

  get classificationType(): string {

    if ( !this.classificationQualify ) {
      return 'laps';
    }

    if ( this.classificationQualify.points ) {
      return 'points';
    }

    if ( this.classificationQualify.classificationType === 0 ) {
      return 'laps';
    }

    return 'best-lap';
  }


  get classificationPhase(): string {

    let type = 'RESULTS.CLASSIFICATION';

    switch ( this.viewSelected.value ) {

      case 'mains':
          type = 'RESULTS.FINAL';
          break;

    }

    return type;
  }


  constructor( private translate: TranslateService,
               private results: ResultsService ) { }


  setupRace( r: Race ): void {
    // Clear context;
    this.run = null;
    this.round = null;
    this._runInfo = [];

    // Load View for current round
    this.loadViewOptions( r );
  }


  /*
   * Load view option (first left selector) ( Overview/Prequalify/Qualify/Mains)
   */
  loadViewOptions( r: Race ): void {

    const options: Option[] = [ this.defaultOption ];
    this.qualifySelected = null;
    this.roundSelected = null;


    if ( this._race.prequalify.rounds.length > 0 ) {
      options.push({ option: 'RESULTS.PREQUALIFY', value: 'prequalify' });
    }

    if ( this._race.qualify.rounds.length > 0 ) {
      options.push({ option: 'RESULTS.QUALIFY', value: 'qualify' });
    }

    if ( this._race.mains.rounds.length > 0 ) {
      options.push({ option: 'RESULTS.MAINS', value: 'mains' });
    }

    this.viewOptions = options;
    this.viewSelected = options[0];
  }



  /*
   * Load qualify/main options (middle selector) ( Q1, Q2, .. )
   */
  loadQualifyOptions( q: Qualify, type: string ): void {

    const options: Option[] = [ this.defaultOption ];

    q.rounds.forEach( ( round, index ) => {
      options.push({ option: `${ type } ${ round.number }`, value: ( index + 1 ).toString() });
    });

    this.qualifyOptions = options;
    this.qualifySelected = options[0];
  }



  getRunInfo( runId: number ): RunInfo {

    return this._runInfo.find( run => {
      // tslint:disable-next-line: triple-equals
      return run.run_zround_id == runId;
    });
  }

  getRacer( racerId: number ): Racer {
    // console.log( `getRacer(${ racerId })`, this.racers );
    return this.racers.find( r => r.id === racerId );
  }


  /*
   * Load rounds options (right selector) ( round 1, 2, ... depending on the q/main selected on the middle selector)
   */
  loadRoundOptions( index: number ): void {

    // console.log( 'loadRoundOptions: ', index );
    const options: Option[] = [ this.defaultOption ];
    const q = this.classificationQualify;
    const round = q.rounds[ index ];

    round.runs.forEach( runId => {
      const info = this.getRunInfo( runId );
      if ( info ) {
        // console.log( 'Adding run', info );
        options.push({ option: info.run_name, value: runId.toString() });
      } else {
        console.warn( 'Run info not found for runId: ', runId );
      }
    });

    // console.log('Setting options to: ', options);
    this.roundOptions = options;
    this.roundSelected = options[0];
  }


  viewOptionChanged(): void {

    this.qualifySelected = null;
    this.roundSelected = null;

    switch ( this.viewSelected.value ) {

      case 'prequalify':
          this.translate.get('RESULTS.Q')
            .subscribe( ( s: string ) => this.loadQualifyOptions( this.race.prequalify, s ) );
          break;

      case 'qualify':
          this.translate.get('RESULTS.Q')
            .subscribe( ( s: string ) => this.loadQualifyOptions( this.race.qualify, s ) );
          break;

      case 'mains':
          this.translate.get('RESULTS.FINAL')
            .subscribe( ( s: string ) => this.loadQualifyOptions( this.race.mains, s ) );
          break;

    }
  }

  qualifyOptionChanged(): void {

    this.roundSelected = null;

    // Load round info if selected qualify item is no overview
    if ( this.qualifySelected.value !== '0' ) {

      this.round = this.classificationQualify.rounds[ +this.qualifySelected.value - 1 ];

      if ( this._runInfo.length === 0 ) {
        // console.log( 'Qualify selected. No RunInfo ... loading ');
        this.results.getRunDictionary( this.race.raceid )
            .subscribe( ( runs: RunInfo[] ) => {
              // console.log( 'RunInfo received: ', runs );
              this._runInfo = runs;
              this.loadRoundOptions( +this.qualifySelected.value - 1 );
            });
      } else {
        this.loadRoundOptions( +this.qualifySelected.value - 1 );
      }
    }
  }


  roundOptionChanged(): void {

    // console.log( 'Round selected', this.roundSelected );
    if ( this.roundSelected.value !== '0' ) {

      const run = this.getRunInfo( +this.roundSelected.value );

      // run.classification must be loaded, then it is cached.
      if ( !run.results ) {

        this.results.getRun( run.run_id )
          .subscribe( results =>  {
            // console.log('Loaded run results: ', results );
            run.results = results.data;

            // Fill in racer data
            run.results.classification.forEach( ( c, index ) => {
              if ( !c.racerInfo ) {
                c.racerInfo = this.getRacer( c.racerid );
              }
            });

            // ! If racer info is not found classification row will be removed. 
            // ! It belongs to "unregistered racer" that should be corrected on ZRound before uploading data
            run.results.classification = run.results.classification.filter( c => c.racerInfo );

            // console.log('Run selected: ', run );
            this.run = run;
          });

      } else {
        // console.log('Run selected: ', run );
        this.run = run;

      }
    } else {

      this.run = null;

    }

  }

}
