import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';

// NGX libraries
import { NgxPaginationModule } from 'ngx-pagination';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


// App routing
import { AppRoutingModule } from './app-routing.module';

//Admin ppages
import { AdmModule} from './adm/adm.module';

// Components
import { AppComponent } from './app.component';
import { TrackComponent } from './pages/track/track.component';
import { ChampionshipComponent } from './pages/championship/championship.component';
import { EventComponent } from './pages/event/event.component';
import { GroupComponent } from './components/group/group.component';
import { LapChartComponent } from './components/lap-chart/lap-chart.component';
import { TimeTableComponent } from './components/time-table/time-table.component';
import { LiveSessionsComponent } from './components/live-sessions/live-sessions.component';
import { PublicationTableComponent } from './components/publication-table/publication-table.component';
import { ChampionshipPointsComponent } from './components/championshipPoints/championshipPoints.component';

// Shared resources
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';

// Pages
import { HomeComponent } from './pages/home/home.component';
import { ClubComponent } from './components/club/club.component';
import { RaceComponent } from './components/race/race.component';
import { RaceResultsComponent } from './components/race-results/race-results.component';
import { RankingQComponent } from './components/ranking-q/ranking-q.component';
import { RankingStartComponent } from './components/ranking-start/ranking-start.component';
import { ClassificationComponent } from './components/classification/classification.component';
import { RoundResultsComponent } from './components/round-results/round-results.component';
import { RunResultsComponent } from './components/run-results/run-results.component';
import { GroupNamePipe } from './pipes/group-name.pipe';
import { RunClassificationComponent } from './components/run-classification/run-classification.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    TrackComponent,
    EventComponent,
    ChampionshipComponent,
    GroupComponent,
    LapChartComponent,
    TimeTableComponent,
    LiveSessionsComponent,
    PublicationTableComponent,
    ClubComponent,
    ChampionshipPointsComponent,
    RaceComponent,
    RaceResultsComponent,
    RankingQComponent,
    RankingStartComponent,
    ClassificationComponent,
    RoundResultsComponent,
    RunResultsComponent,
    GroupNamePipe,
    RunClassificationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgxPaginationModule,
    ChartsModule,
    AdmModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
         provide: TranslateLoader,
         useFactory: httpTranslateLoader,
         deps: [HttpClient]
         }
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

// AOT compilation support
export function httpTranslateLoader( http: HttpClient ): any {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
