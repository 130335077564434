import { Component, Input } from '@angular/core';
import { Racer } from 'src/app/interfaces/racer.interface';

@Component({
  selector: 'app-ranking-start',
  templateUrl: './ranking-start.component.html',
  styles: [
  ]
})
export class RankingStartComponent {

  @Input() racers: Racer[];
  @Input() title: string;

  constructor() { }

}
