<div class="container-fluid mt-2" *ngIf="usuario">
    <div class="row">

        <div class="col col-lg-6">

            <div class="card">
                <div class="card-body">
                    <h3 class="card-title">{{ !usuario.id ? 'Crear ' : 'Actualizar ' }} Usuario</h3>
                    <h6 class="card-subtitle">Asociación de usuario <span class="small-login">{{ usuario?.login }}</span> con ZRound Services</h6>

                    <form autocomplete="on" [formGroup]="form" (ngSubmit)="enviar()" class="form-horizontal p-t-20">

                        <div class="form-group row">
                            <label for="id" class="col-sm-2 control-label">Id</label>
                            <div class="col-sm-2 col-xl-4">
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-key"></i></div>
                                    <input type="text" class="form-control" formControlName="id" id="id" readonly>
                                </div>
                            </div>
                            <small class="text-secondary m-l-5" *ngIf="usuario && usuario.id">
                                <i>Creado el {{ usuario.created_on | date:'dd/MM/yy' }}</i>
                            </small>
                        </div>

                        <div class="form-group row">
                            <label for="login" class="col-sm-2 control-label">Login*</label>
                            <div class="col-sm-4 col-xl-5">
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-user"></i></div>
                                    <input type="text" class="form-control" formControlName="login" id="login" placeholder="Cuenta de usuario" readonly>
                                    </div>
                            </div>
                        </div>
                        
                        <div class="form-group row">
                            <label for="rol" class="col-sm-2 control-label">Rol</label>
                            <div class="col-sm-4 col-xl-5">
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-crown"></i></div>
                                    <select class="form-control" formControlName="role">
                                        <option value="">Seleccione rol</option>
                                        <option *ngFor="let rol of roles" [value]="rol">{{ rol }}</option>
                                    </select>
                                    <small *ngIf="rolNoValido" class="text-danger">
                                        El campo rol es obligatorio
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="email" class="col-sm-2 control-label">Email</label>
                            <div class="col-sm-9 col-xl">
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-email"></i></div>
                                    <input type="text" class="form-control" formControlName="user_email" id="email" placeholder="email registrado" readonly>
                                    </div>
                            </div>        
                        </div>    

                        <div class="form-group row">
                            <label for="display_name" class="col-sm-2 control-label">Nombre a mostrar</label>
                            <div class="col-sm-9 col-xl">
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-info-alt"></i></div>
                                    <input type="text" class="form-control" formControlName="display_name" id="display_name" placeholder="Nombre a mostrar o descripción">
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="display_name" class="col-sm-2 control-label">www</label>
                            <div class="col-sm-9 col-xl">
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-world"></i></div>
                                    <input type="url" class="form-control" formControlName="www" id="www" placeholder="Web del usuario">
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="display_name" class="col-sm-2 control-label">Subtítulo</label>
                            <div class="col-sm-9 col-xl">
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-info"></i></div>
                                    <input type="text" class="form-control" formControlName="slogan" id="slogan" placeholder="Subtítulo">
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="auth_key" class="col-sm-2 control-label">Auth. Key</label>
                            <div class="col col-sm-5">
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-lock"></i></div>
                                    <input type="text" class="form-control" formControlName="auth_key" id="auth_key" placeholder="Código de autorización">
                                    <button type="button" class="btn btn-outline-secondary ti-reload" (click)="regeneraAuth()"></button>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row mt-2">
                            <div class="offset-sm-2 col-sm-9">
                                <div class="checkbox checkbox-success">
                                    <input id="activo" type="checkbox" formControlName="active">
                                    <label for="activo" class="control-label">Cuenta activa</label>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col">
                                <h3>Servicio Live</h3>
                            </div>
                        </div>
                        <div class="form-group row">                        
                            <label for="live_start_date" class="col-sm-4 col-lg-3 col-xl-4 control-label">Start date</label>
                            <div class="col col-sm-5 col-xl-6">
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-face-smile"></i></div>
                                    <input type="datetime-local" class="form-control" formControlName="live_start_date" id="live_start_date">
                                </div>
                            </div>
                        </div>
                                
                        <div class="form-group row">
                            <label for="live_expiration" class="col-sm-4 col-lg-3 col-xl-4 control-label">Expiration date</label>
                            <div class="col col-sm-5 col-xl-6">
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-face-sad"></i></div>
                                    <input type="datetime-local" class="form-control" formControlName="live_expiration" id="live_expiration">
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col offset-sm-4 offset-lg-3 offset-xl-4 mt-2">
                                <div class="checkbox checkbox-success">
                                    <input id="live_enabled" type="checkbox" formControlName="liveEnabled">
                                    <label for="live_enabled" class="control-label">Servicio activo</label>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row m-b-0 mt-3">
                            <div class="offset-sm-2 col-sm-9">
                                <button type="submit" class="btn btn-success waves-effect waves-light" (submit)="enviar()"><i class="ti-save"></i> Guardar</button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>

        <div class="col col-lg-6">
            <div class="card" *ngIf="usuario">
                <div class="card-body">
                    <div class="form-group row">
                        <div class="col-sm-2">

                            <img class="w-100 pointer"
                                *ngIf="!imgTemp"
                                [alt]="usuario.display_name"
                                [src]="usuario.img | img">

                            <img *ngIf="imgTemp"
                                [src]="imgTemp"
                                class="w-100">

                            <input  type="file"
                                    (change)="cambiarImagen( $event.target.files[0] )">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>    
