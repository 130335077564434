<div style="display: block">
    <div style="display: flex; flex-wrap: wrap; align-items: stretch;">
        <app-club [club]="club"></app-club>
        <div class="zround-box ml-2" style="flex-grow: 4">
            <div class="row data">
                <div class="col">
                    <h2>{{ event.data.name }}</h2>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <button [disabled]="!selectedRace" class="btn btn-info btn-championship mt-3 mr-3" (click)="selectedRace = null">
                        {{ 'RESULTS.CLASSIFICATION' | translate }}
                    </button>
                    <button *ngFor="let race of races" [disabled]="selectedRace === race.data || !race.raceid" class="btn btn-success btn-championship mr-2 mt-3" (click)="selectRace( race )">
                        {{ race.name }}
                    </button>
                </div>
            </div>
        </div>
    </div>    
    <div>
        <div *ngIf="!selectedRace" class="event-classification">
            <div class="ml-3" *ngFor="let race of event.data.races; trackBy: trackRace">
                <app-race-results class="ml-3" [race]="race"></app-race-results>
            </div>
        </div>
        <app-race *ngIf="selectedRace" [race]="selectedRace"></app-race>
    </div>
</div>