<div class="club-card zround-box">
    <div class="logo">
        <a id="usr_link" [routerLink]="'/track/' + club.id"><img class="logo" [src]="club.img"></a>
    </div>
    <div class="data ml-3">
        <h3>{{ club.display_name }}</h3>
        <h4>{{ club.slogan }}</h4>
        <a class="link" target="_blank" [href]="club.www" id="www"><span id="www_name"><i class="fa fa-globe"></i> {{ club.www }}</span></a>
    </div>
</div>
