import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {

  form: FormGroup;

  get usuarioNoValido() {
    return this.form.get('usuario').invalid && this.form.get('usuario').touched;
  }

  get passNoValido() {
    return this.form.get('pass').invalid && this.form.get('pass').touched;
  }

  get usuario() {
    return this.form.get('usuario');
  }

  get pass() {
    return this.form.get('pass');
  }

  get recordar() {
    return this.form.get('recordar');
  }

  constructor(  private fb: FormBuilder,
                private auth: AuthService,
                private router: Router ) {

      this.crearFormulario();

      if ( auth.estaAutenticado ) {
        router.navigateByUrl('/usuarios');
      }
  }

  ngOnInit() {

    this.cargarDatosFormulario();

  }

  crearFormulario() {

    this.form = this.fb.group({
      usuario : ['', [ Validators.required]  ],
      pass    : ['', Validators.required ],
      recordar: [''],
    });

  }


  cargarDatosFormulario() {

    if ( localStorage.getItem('usuario') ) {

      this.form.reset({
        usuario: localStorage.getItem('usuario'),
        recordar: true,
      });
    }

  }

  login() {

    if (  this.form.invalid ) {
      return Object.values( this.form.controls ).forEach( control => {
        
        if ( control instanceof FormGroup ) {
          Object.values( control.controls ).forEach( control => control.markAsTouched() );
        } else {
          control.markAsTouched();
        }
        
        
      });
    }

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Espere por favor...'
    });
    Swal.showLoading();

    this.auth.login( this.usuario.value, this.pass.value )
      .subscribe( resp => {

        Swal.close();

        if ( this.recordar.value ) {
          localStorage.setItem( 'usuario', this.usuario.value );
        }

        this.router.navigateByUrl( '/usuarios' );

      }, (err) => {

        Swal.fire({
          icon: 'error',
          title: 'Error al autenticar',
          text: `${ err.status } - ${ err.statusText }`
        });
        
      });

  }

}
