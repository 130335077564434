<table class="table table-sm table-striped resumen">
    <thead class="thead-dark">
        <tr>
            <th>{{ 'RESULTS.POS' | translate }}</th>
            <th>{{ 'RESULTS.NAME' | translate }}</th>
            <th class="d-none d-sm-table-cell">{{ 'RESULTS.CLUB' | translate }}</th>
            <th style="width: 140px">{{ phase | translate }} </th>
            <th *ngFor="let q of qualify.rounds; let j=index">
                {{ ( 'RESULTS.'+roundType ) | translate}} {{ j + 1 }}
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let racer of qualify.classification; let i=index">
            <td>
                <div class="posicion circulo-resaltado">{{ i + 1 }}</div>
            </td>
            <td>{{ racer.racerInfo.name }}<span class="racer_alias">{{ racer.racerInfo.alias }}</span></td>
            <td class="d-none d-sm-table-cell">{{ racer.racerInfo.club }}</td>

            <td class="qualyResult qualyResult-data">
                <div class="font-weight-bold text-center">{{ getClassificationResult( racer ) }}</div>
            </td>

            <td *ngFor="let round of qualify.rounds"
                class="qualyResult qualyResult-data"
                [ngClass]="{ 'result-qualify': getRacerResult( racer, round ) ? racerResult.qualify > 0 : false }">

                <div *ngIf="racerResult">
                    <div style="display: flex; flex-wrap: wrap; justify-content: space-around">
                        <div class="posicion" [ngClass]="positionClass( racerResult.pos )">{{ racerResult.pos }}</div>
                        <div class="">{{ getClassificationResult( racerResult ) }}</div>
                        <div *ngIf="classificationType !== 'best-lap'"
                              class="best-lap"><small>{{ racerResult.best }}</small></div>
                    </div>
                </div>

            </td>
        </tr>
    </tbody>
</table>
