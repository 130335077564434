import { Component, Input } from '@angular/core';
import { Qualify } from 'src/app/interfaces/race.interface';
import { Racer } from 'src/app/interfaces/racer.interface';

@Component({
  selector: 'app-ranking-q',
  templateUrl: './ranking-q.component.html',
  styleUrls: []
})
export class RankingQComponent {

  @Input() qualify: Qualify;
  @Input() title: string;

  constructor() { }

}
